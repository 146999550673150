.testConstructor {
  margin-top: 50px;

  &__title {
    margin-bottom: 20px;
  }

  &__save {
    margin-top: 40px;
  }

  &__list {
    margin-bottom: 30px;
  }

  &__item {
    padding: 10px;
    border: 1px solid $grey;
    border-radius: 5px;
    background-color: $white;

    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid $grey;
      margin-bottom: 20px;
    }
  }

  &__item-top {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  &__choices {
    padding: 10px;
    border-radius: 10px;
    background-color: $blueLight;
    border: 1px solid $grey;
    margin-top: 20px;

    &._error {
      background-color: #da2a2a25;
    }
  }

  &__choices-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__add-choice {
    margin-top: 20px;
  }

  &__inputs-manager {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;

    &>* {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  &__choices-error {
    margin-top: 15px;
    font-size: 12px;
    color: red;
    font-weight: 600;
  }

  &__quest-img-wrap {
    margin-top: 10px;
    max-width: 500px;
    width: fit-content;
    max-height: 400px;
    position: relative;
    &:hover{
      & .testConstructor__quest-img-wrap-btns{
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__quest-img-wrap-btns {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
  }

  &__quest-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__input-wrap {
    &._correct {
      & input {
        border-color: green;
        background-color: $greenLight;
        box-shadow: 4px 4px 10px 0px rgba(35, 212, 59, 0.3);
      }

      & img {
        box-shadow: 0px 0px 15px 0px rgba(35, 212, 59, 1);
      }
    }

    &._error {
      & input {
        border-color: #da2a2a;
        box-shadow: 4px 4px 10px 0px rgba(245, 53, 5, 0.3);
        color: #da2a2a;
      }
    }

    & img {
      object-fit: contain;
      max-width: 500px;
      max-height: 300px;
      transition: box-shadow 0.3s;
    }
  }

  & .ant-upload-list {
    display: none;
  }
  &__pass-label{
    padding: 10px;
    border-radius: 10px;
    background-color: $greenLight;
    border: 1px solid $grey;
    margin-top: 20px
  }
}