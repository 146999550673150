.rate {
  &__list {
    margin-bottom: 30px;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    line-height: 25px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 20px;
    align-items: flex-start;
  }
  &__input {
    max-width: 100%;
    width: 100%;
    padding: 2px 5px;
    border: 1px solid $grey;
    border-radius: 2px;
    line-height: 1.2;
    outline: none;
    &:focus {
      border-color: $blue;
    }
  }
}
