
$grey: var(--grey-color);
$white: var(--white-color);
$green: var(--green-color);
$greenLight: var(--green-light-color);
$yellowLight: var(--yellow-light-color);
$yellow: var(--yellow-color);
$blue: var(--blue-color);
$purple: var(--purple-color);
$blueLight: var(--blue-light-color);

$mainFont: var(--main-font);


:root {
  --grey-color: #67676A;
  --white-color: #ffffff;
  --green-color: #A1DA67;
  --green-light-color: #D0ECB3;
  --yellow-light-color: #FFF1CB;
  --yellow-color: #FFD152;
  --blue-color: #8FA9FF;
  --purple-color: #FF80DD;
  --blue-light-color: #DDE5FF;



  --main-font: 'Raleway', sans-serif;

}



$big-r: 1380;
$desm-r: 1280;
$tab-r: 1024;
$mtab-r: 980;
$stab-r: 768;
$bmob-r: 576;
$smob-r: 400;




@mixin adpt($width) {
  @media (max-width: #{$width}px) {
    @content;
  }
}

@mixin adpt-mob($width) {
  @media (min-width: #{$width}px) {
    @content;
  }
}

@include adpt ($bmob-r) {}