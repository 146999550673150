.listLessons {
  &__title {
    margin-bottom: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__list {
    margin-top: 50px;


  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &__item-title {
    font-weight: 700;
    font-size: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey;
    margin-bottom: 30px;
  }

  &__list-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @include adpt ($bmob-r) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__link {
    position: relative;

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }

  &__count {
    font-weight: 700;
    font-size: 25px;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }
  }

  &__item-2 {
    &._complete {
      & a {
        padding-left: 29px;

        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url('../../../images/icon/check3.svg');
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          top: 0;
        }
      }
    }
  }
}