.test {
  padding: 30px;
  background-color: $blueLight;
  border-radius: 20px;

  &__title {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
  }

  &__btn {
    margin-top: 20px;

    @include adpt ($bmob-r) {
      width: 100%;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
    color: $grey;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }

    & img {
      margin: 20px 0;
      object-fit: contain;
      max-width: 100%;
      max-height: 300px;
    }
  }

  &__answers {
    margin-bottom: 10px;
  }

  &__answer-inner {
    color: $grey;
    font-size: 18px;

    @include adpt ($bmob-r) {
      font-size: 15px;
    }
  }

  &__answer {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__input-wrap {
    display: flex;
    align-items: center;

    & span {
      color: $grey;
      font-size: 18px;
      margin-right: 10px;

      @include adpt ($bmob-r) {
        font-size: 15px;
      }
    }

    &._error {
      & input {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.411);
      }
    }
  }

  &__input {
    max-width: 100%;
    width: 100%;
    background-color: $white;
    padding: 5px;
    outline: none;

  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &._error {
      padding: 15px 5px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(255, 0, 0, 0.411);
      background-color: rgba(255, 0, 0, 0.16);
      transition: all 0.3s;
    }
    &.false{
      padding: 15px 5px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 255, 0, 0.411);
      background-color: rgba(0, 255, 0, 0.16);
      transition: all 0.3s;
    }
  }

  &__answer-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    pointer-events: none;

    &:checked+label {
      &::before {
        opacity: 1;
      }
    }

    &._radio+label {
      &::before {
        background: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        background-color: $grey;

        @include adpt ($bmob-r) {
          width: 8px;
          height: 8px;
        }
      }

      &::after {
        border-radius: 50%;
        background: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $grey;

        @include adpt ($bmob-r) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__answer-opption {
    padding-left: 34px;
    position: relative;
    cursor: pointer;
    display: flex;

    @include adpt ($bmob-r) {
      padding-left: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      z-index: 1;
      background-image: url(../../../images/icon/check-square-1.svg);
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      transition: opacity .3s;

      @include adpt ($bmob-r) {
        width: 20px;
        height: 20px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-image: url(../../../images/icon/check-square-0.svg);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
      transition: opacity .3s;

      @include adpt ($bmob-r) {
        width: 20px;
        height: 20px;
      }
    }

    & img {
      display: inline-block;
      max-width: 100%;
      max-height: 200px;
      object-fit: contain;
    }
  }

  &__success {
    margin-top: 30px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -60px;
      right: 0;
      width: 264px;
      height: 185px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../../images/cat/class3z.svg');

      @include adpt ($stab-r) {
        display: none;
      }
    }
  }

  &__success-title {
    max-width: 554px;
    margin-bottom: 30px;

    @include adpt ($bmob-r) {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__success-btn-wrap {
    display: flex;
    max-width: 479px;

    @include adpt ($bmob-r) {
      flex-direction: column;
    }
  }

  &__success-btn-link {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 12px;

    &:not(:last-child) {
      margin-right: 10px;

      @include adpt ($bmob-r) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}