.lessons {
 

  &__quarter {
    background-color: $white;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid $grey;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__top-right {
    margin-left: 20px;
    display: flex;
    align-items: center;

    &>* {
      &:not(:last-child) {
        margin-right: 7px;
      }
    }
  }

  &__top-text {
    font-weight: 600;
    font-size: 20px;
  }

  &__list {
    border: 1px solid $grey;
    background-color: $blueLight;
    border-radius: 20px;
    padding: 20px;
    margin-top: 10px;
    transition: box-shadow 0.3s;
  }

  &__item {
    padding: 5px 10px;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &._end {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
    }
  }

  &__item-btn-wrap {
    display: flex;
    align-items: center;

    &>*:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__item-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    &._grab {
      cursor: grab;
    }
  }

  &__btn-add {
    margin: 0;
    margin-top: 10px;
    background-color: $white;
  }

  &__add {
    margin-bottom: 20px;
  }

  &__add-text {
    margin-bottom: 20px;
  }
}