.popup-cab {
  &__content {
    max-width: 590px;
    overflow: hidden;
    background-color: $white;
  }

  &__title {
    color: $grey;
    text-align: left;
    position: relative;
    z-index: 1;

    @include adpt ($bmob-r) {
      text-align: center;
    }
  }

  &__input {
    border: 1px solid $grey;
    resize: vertical;
  }

  &__img-decor {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    position: relative;
    z-index: 1;
  }

  &__input-wrap._error {
    & .input__input {
      box-shadow: 0px 0px 10px rgba(185, 68, 68, 0.6);
      color: #d11616;
    }
  }

  &__link {
    max-width: 240px;
    padding: 10px 30px;

    @include adpt ($bmob-r) {
      max-width: 100%;
    }
  }

  &__link-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include adpt ($bmob-r) {
      flex-direction: column;
    }

    & >* {
      @include adpt ($bmob-r) {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 20px;

        @include adpt ($bmob-r) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}