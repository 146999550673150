.documents {
  &__title{
    @include adpt ($bmob-r){
      font-size: 28px;
    }
  }
  &__list {
    margin: 50px 0;

    @include adpt ($bmob-r) {
      margin-top: 30px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 50px;
      @include adpt ($bmob-r) {
        margin-bottom: 30px;
      }
    }
  }

  &__name {
    margin-bottom: 20px;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }
  }
  &__prev{
    & img{
      max-width: 350px;
      width: 100%;
      height: auto;
    }
  }
}