.lesson {
  &__title {
    margin-bottom: 30px;
  }

  &__theme {
    font-weight: 700;
    font-size: 25px;
    line-height: 1.3;
    margin-bottom: 20px;

    @include adpt ($bmob-r) {
      font-size: 20px;
    }
  }

  &__content {
    margin-bottom: 30px;
    padding: 0;
    border-radius: 0;
    display: flex;
    border: none;
    background-color: transparent;
  }
}