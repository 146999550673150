.subjects {
  &__title {
    margin-bottom: 50px;
  }

  &__btn {
    padding-left: 30px;
    position: relative;
    margin-bottom: 50px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 5px;
      width: 20px;
      height: 20px;
      background-image: url('../../../images/icon/Plus_icon-icons.com_71848.svg');
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
}