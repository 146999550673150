.header {
  position: relative;
  z-index: 10;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 19px;
    border-bottom: 1px solid $grey;
  }

  &__logo-wrap {
    flex: 1;
    display: flex;
    max-width: 360px;

    @include adpt ($tab-r) {
      max-width: 260px;
    }

    @include adpt ($stab-r) {
      max-width: 200px;
    }

    @include adpt ($bmob-r) {
      max-width: 130px;
    }

    @include adpt ($smob-r) {
      max-width: 125px;
    }

    &._2 {
      @include adpt (380) {
        max-width: 100px;
      }
    }
  }

  &__nav-wrap {
    margin: 0 30px;

    @include adpt ($tab-r) {
      flex: 1;
      margin-right: 50px;
    }

    @include adpt ($mtab-r) {
      margin: 0 30px;
    }

    @include adpt ($stab-r) {
      position: fixed;
      z-index: 30;
      top: 0;
      left: 0;
      background-color: $white;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      margin: 0;
      padding: 30px 50px;
      transform: translateY(-20px);
      opacity: 0;
      pointer-events: none;
      transition: transform 0.4s ease, opacity 0.3s;

      &._active {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;

    @include adpt ($tab-r) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }

  &__right-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include adpt ($tab-r) {
      flex: 1;
      max-width: 620px;
    }

    @include adpt ($stab-r) {
      justify-content: flex-end;
    }

    @include adpt ($bmob-r) {
      justify-content: space-between;
      margin-left: 46px;
      max-width: 200px;
    }

    @include adpt ($smob-r) {
      margin-left: 14px;
    }

    &._2 {
      @include adpt ($bmob-r) {
        max-width: 223px;
        margin-left: 10px;
      }
    }
  }

  &__phone {
    text-align: center;
    white-space: nowrap;
    margin-right: 30px;

    @include adpt ($tab-r) {
      margin-right: 0;
    }

    @include adpt ($stab-r) {
      margin-right: 60px;
    }

    @include adpt ($bmob-r) {
      margin-right: 10px;
    }

    &._2 {
      & a {
        @include adpt ($bmob-r) {
          font-size: 15px;
        }
      }
    }
  }

  &__phone-link {
    display: block;

    @include adpt ($bmob-r) {
      font-size: 15px;
    }
  }

  &__btn {
    @include adpt ($tab-r) {
      display: none;
    }
  }

  &__schedule {
    display: block;
    text-align: center;

    @include adpt ($tab-r) {
      font-size: 15px;
    }

    @include adpt ($bmob-r) {
      font-size: 13px;
    }
  }

  &__burger {
    display: none;

    @include adpt ($stab-r) {
      display: block;
      flex-shrink: 0;
    }

    &_close {
      position: fixed;
      top: 20px;
      right: 20px;
    }
  }

  &__auth-btn-block {
    position: relative;
    margin-left: 18px;

    @include adpt ($bmob-r) {
      margin-left: 5px;
    }

    &._auth {
      margin-left: 0;

      @include adpt ($tab-r) {
        margin-left: 18px;
      }

      @include adpt ($bmob-r) {
        margin-left: 5px;
      }

      & .header__auth-list-wrap {
        padding-top: 16px;
      }

      & .header__auth-list {
        width: auto;
      }
    }

    &:hover {
      & .header__auth-list-wrap {
        opacity: 1;
        pointer-events: all;
        transform: translate(0);
      }
    }
  }

  &__auth-btn {
    width: 40px;
    height: 40px;
    margin-bottom: -2px;
  }

  &__auth-btn-icon {
    position: relative;
    object-fit: contain;
  }

  &__auth-list-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);
    transition: all 0.3s ease;
  }

  &__auth-list {
    width: 194px;
    padding: 20px 30px;
    border-radius: 20px;
    border: 1px solid $grey;
    background-color: #FDFDFF;
    box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.30);

    @include adpt ($bmob-r) {
      padding: 20px;
    }
  }

  &__auth-item {
    &._active {
      font-weight: 700;
      font-size: 20px;
      text-wrap: nowrap;

      @include adpt ($bmob-r) {
        font-size: 18px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__auth-link {
    position: relative;
    text-wrap: nowrap;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &._active {
      font-weight: 700;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $yellow;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

}