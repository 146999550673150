.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  transition: all 0.5s ease;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &._doc {
    & .popup__body {
      padding-top: 100px;

      @include adpt ($bmob-r) {
        padding: 35px 40px 35px 20px;
      }
    }

    & .popup__content {
      border-radius: 0;
      padding: 0;
      max-width: 630px;
      background-color: transparent;
    }

    & .popup__close {
      right: -40px;
      top: 0;
    }
  }

  &__body {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;

    @include adpt($bmob-r) {
      padding: 15px;
    }
  }

  &__content {
    position: relative;
    max-width: 650px;
    background-color: $blue;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    border-radius: 30px;
    width: 100%;
    padding: 50px 30px;

    @include adpt($big-r) {
      max-width: 550px;
    }

    @include adpt ($bmob-r) {
      padding: 50px 20px;
    }

    &._class {
      max-width: 320px;
      padding: 50px 20px;

      & .popup__close {
        top: 10px;
        right: 10px;
      }
    }

    &._auth {
      background-color: $white;
      color: $grey;
    }

  }




  &__close {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 30px;
    top: 10px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    @include adpt ($bmob-r) {
      right: 10px;
    }

    &:hover {
      transform: rotate(90deg);
    }

    &:active {
      transform: rotate(90deg) scale(0.8);
    }
  }

  &__close-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    color: $white;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    max-width: 500px;
    margin: 0 auto 30px;

    @include adpt ($bmob-r) {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &._auth {
      text-align-last: left;
      color: $grey;
      margin: 0;
      margin-bottom: 30px;
    }
  }

  &__text {
    color: $white;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 30px;

      @include adpt ($bmob-r) {
        margin-bottom: 20px;
      }
    }

    &._auth {
      text-align: left;
      color: $grey;
    }

    & a {
      text-decoration: underline;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__classes-item {
    display: flex;
    justify-content: space-around;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__classes-link {
    color: $white;

    &:hover {
      color: $yellow;
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }

  &__doc-inner {
    display: flex;
    flex-direction: column;

  }

  &__doc-img {
    width: 100%;
    height: auto;
    object-fit: contain;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__auth-btn-block {
    margin-bottom: 30px;

    @include adpt ($bmob-r) {
      margin-bottom: 20px;
    }
  }

  &__auth-btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include adpt ($bmob-r) {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include adpt ($bmob-r) {
        margin-bottom: 20px;
      }
    }
  }

  &__auth-btn {
    flex: 1;
    padding: 18px;
    border-radius: 20px;
    border: 1px solid $grey;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;

    @include adpt ($bmob-r) {
      width: 100%;
      padding: 15px;
    }

    &:hover {
      background-color: $green;
      color: $white;
      border-color: $green;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include adpt ($bmob-r) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}