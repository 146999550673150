.preparation {
  &__subject-list {
    margin: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    @include adpt ($stab-r) {
      grid-template-columns: 1fr 1fr;
    }

    @include adpt ($bmob-r) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
      margin-top: 30px;
    }
  }
}