.menu {
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include adpt ($tab-r) {
      width: 100%;
    }

    @include adpt ($stab-r) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 29px;

      @include adpt ($stab-r) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &:nth-child(3) {
      @include adpt ($stab-r) {
        order: -2;
        margin-bottom: 20px;
      }
    }

    &:nth-child(2) {
      @include adpt ($stab-r) {
        order: -1;
      }
    }

    &:nth-child(1) {
      @include adpt ($stab-r) {
        order: -3;
      }
    }
  }

  &__item-top {
    position: relative;

    &:hover {
      & .menu__link {
        &._arrow {
          &::after {
            transform: rotate(180deg) translateY(-2px);
          }
        }

        &::before {
          opacity: 1;
        }

      }

      & .menu__sublist-wrap {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
      }
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    cursor: pointer;

    @include adpt ($stab-r) {
      font-weight: 700;
    }

    @include adpt ($bmob-r) {
      font-size: 18px;
    }

    &._arrow {
      padding-right: 24px;

      @include adpt ($stab-r) {
        padding-right: 0;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 24px;
        background-image: url('../../images/icon/chevron-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform 0.3s;

        @include adpt ($stab-r) {
          display: none;
        }
      }

      &::before {
        width: calc(100% - 24px);
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $yellow;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;

      @include adpt ($stab-r) {
        display: none;
      }
    }
  }

  &__sublist-wrap {
    position: absolute;
    padding-top: 21px;
    left: -30px;
    top: 100%;
    z-index: 20;
    width: 224px;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;

    @include adpt ($stab-r) {
      opacity: 1;
      position: relative;
      transform: translate(0);
      padding-top: 10px;
      left: 0;
      width: 100%;
      max-width: 170px;
    }

    &._second {
      width: 260px;

      @include adpt ($stab-r) {
        width: 100%;
      }

      & .menu__sublist {
        grid-template-columns: 1fr;
      }
    }
  }

  &__sublist {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 20px;
    padding: 19px 28px;
    border: 1px solid $grey;
    background-color: #FDFDFF;
    box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.30);
    grid-gap: 10px 20px;

    @include adpt ($stab-r) {
      padding: 0;
      box-shadow: none;
      border: none;
      border-radius: 0;
    }
  }

  &__sublink {
    position: relative;

    @include adpt ($stab-r) {
      font-size: 15px;
      display: block;
    }

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }

  &__btn {
    display: none;

    @include adpt ($stab-r) {
      display: block;
      font-size: 15px;
      padding: 7px 20px 5px;
    }
  }


}