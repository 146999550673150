.textBook-students {
  &__title {
    @include adpt ($bmob-r) {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    @include adpt ($stab-r) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include adpt ($bmob-r) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;

    &:hover {
      & .textBook-students__img {
        transform: scale(1.05);
      }
    }
  }

  &__btn {
    margin-top: 30px;
  }

  &__img-wrap {
    border-radius: 5px;
    overflow: hidden;
    aspect-ratio: 150/200;
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
    height: auto;

    @include adpt ($bmob-r) {
      aspect-ratio: 150/200;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }

  &__name {
    color: $grey;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }
  }

  &__author {
    color: $grey;

    @include adpt ($bmob-r) {
      font-size: 15px;
    }
  }
}