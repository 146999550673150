.courses {
  &__title {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-bottom: 50px;
  }

  &__link {
    border-radius: 5px;
    background-color: $white;

    &:hover {
      background-color: $grey;
      color: $white;
    }

    &._active {
      background-color: $blueLight;

      &:hover {
        color: $grey;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  &__wrap{
    margin-bottom: 50px;
  }
  &__section{
    &:not(:last-child){
      margin-bottom: 50px;
    }
  }
  &__section-title{
    margin-bottom: 25px;
  }
}