.page404 {

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 512px;
    height: 616px;
    background-image: url('../../images/decor/404.svg');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    pointer-events: none;

    @include adpt ($tab-r) {
      width: 300px;
      height: 370px;
    }

    @include adpt ($bmob-r) {
      width: 250px;
      height: 320px;
    }

    @include adpt ($smob-r) {
      width: 200px;
      height: 300px;
    }
  }
}