html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $green transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 30px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

ul,
ol {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  transition: color 0.3s, opacity 0.3s;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
  border-radius: 0;
  background-color: inherit;
  padding: 0;
  border: none;
}

button {
  border: none;
  cursor: pointer;
}

::selection {
  background: $green;
  color: $white;
}

::-moz-selection {
  background: $green;
  color: $white;
}

::-webkit-selection {
  background: $green;
  color: $white;
}

b {
  font-weight: 700;
}