.promo-page {
  position: relative;
  margin-bottom: 50px;

  @include adpt ($bmob-r) {
    margin-bottom: 30px;
  }

  &._contacts {
    & .promo-page__bg-wrap {
      margin-top: -80px;

      @include adpt ($tab-r) {
        margin-top: 0;
      }
    }
  }

  &._answers {
    & .promo-page__bg-wrap {
      margin-top: -108px;

      @include adpt ($tab-r) {
        margin-top: 0;
      }
    }
  }

  &._documents {
    & .promo-page__bg-wrap {
      margin-top: -105px;

      @include adpt ($tab-r) {
        margin-top: 0;
      }
    }
  }
  &._blog {
    & .promo-page__bg-wrap {
      margin-top: -105px;

      @include adpt ($tab-r) {
        margin-top: -10px;
      }
    }
  }

  &._information {
    & .promo-page__bg-wrap {
      margin-top: -163px;

      @include adpt ($tab-r) {
        margin-top: -100px;
      }

      @include adpt ($stab-r) {
        margin-top: -20px;
      }
    }

    & .promo-page__title {
      @include adpt ($bmob-r) {
        font-size: 40px;
      }

      @include adpt ($smob-r) {
        font-size: 36px;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    margin-bottom: 20px;

    @include adpt ($bmob-r) {
      margin-bottom: 10px;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: contain;
    pointer-events: none;
  }

  &__bg-wrap {
    position: relative;
  }
}