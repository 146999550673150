@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src:
    url('../fonts/Raleway-Bold.woff2') format('woff2'),
    url('../fonts/Raleway-Bold.woff') format('woff'),
    url('../fonts/Raleway-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url('../fonts/Raleway-Regular.woff2') format('woff2'),
    url('../fonts/Raleway-Regular.woff') format('woff'),
    url('../fonts/Raleway-Regular.ttf') format('ttf');
}