.textBook {
  &__form {
    padding: 20px;
    border: 1px solid $grey;
    background-color: #f3f6ff;
    border-radius: 20px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin-bottom: 40px;
  }

  &__btns {
    display: flex;

    &>* {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__item {
    & .input__label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      display: block;
    }

    & .ant__select {
      max-width: 100%;
    }
  }
  &__info{
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }


}