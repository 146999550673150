.input {
  &._error {
    & .input__input {
      border-color: #da2a2a;
      box-shadow: 4px 4px 10px 0px rgba(245, 53, 5, 0.3);
      color: #da2a2a;
    }
  }

  &__input {
    max-width: 100%;
    width: 100%;
    padding: 8px 20px 9px;
    background-color: $white;
    border-radius: 20px;
    transition: box-shadow 0.3s;
    outline: none;

    @include adpt ($bmob-r) {
      padding: 10px 20px 7px;
    }

    &::placeholder {
      color: $grey;
      opacity: 0.6;
    }

    &:focus,
    &:active {
      box-shadow: 4px 4px 10px 0px rgba(107, 128, 137, 0.30);

    }

    &._select {
      padding: 0;
      border-radius: 0;
      background-color: transparent;

      &:focus,
      &:active {
        box-shadow: none;

      }
    }

    &._textarea {
      resize: vertical;
      min-height: 40px;

      @include adpt ($bmob-r) {
        min-height: 74px;
      }
    }

    &._error {
      border-color: #da2a2a;
      box-shadow: 4px 4px 10px 0px rgba(245, 53, 5, 0.3);
      color: #da2a2a;
    }
    &._correct{
      border-color: green;
      background-color: $greenLight;
      box-shadow: 4px 4px 10px 0px rgba(35, 212, 59, 0.3);
    }
  }

  &__error {
    font-size: 12px;
    color: #da2a2a;
    margin-top: 4px;
  }

  & .jq-selectbox__select {
    height: 40px;
    border-radius: 20px;
    padding: 8px 20px 9px;
    padding-right: 54px;
    border: none;
    background: $white;
    box-shadow: none;
    font: inherit;
    color: $grey;
    text-shadow: none;
    transition: all 0.3s;

    &._error {
      color: #da2a2a;
      box-shadow: 4px 4px 10px 0px rgba(245, 53, 5, 0.3);
    }
  }

  & .jq-selectbox .placeholder {
    color: $grey;
  }

  & .jq-selectbox {
    &.focused {
      & .jq-selectbox__select {
        border: none;
      }
    }

    &.opened {
      & .jq-selectbox__select {
        border-radius: 20px 20px 0 0;
      }

      & .jq-selectbox__trigger-arrow {
        transform: rotate(180deg) translateY(calc(50% - 4px));
      }
    }

    & li {
      padding: 0;
      color: $grey;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover {
        background: none;
        color: $grey;
        font-weight: 700;
      }

      &.selected {
        background: none;
      }
    }
  }

  & .jq-selectbox__trigger {
    border-left: none;
    right: 20px;
  }

  & .jq-selectbox__trigger-arrow {
    width: 24px;
    height: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-image: url('../../images/icon/chevron-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform 0.3s;
  }

  & .jq-selectbox__dropdown {
    margin: 0;
    border: none;
    border-radius: 0 0 20px 20px;
    background-color: $white;
    padding: 11px 20px 20px;
    box-shadow: 4px 7px 7px 0px rgba(107, 128, 137, 0.20);
    font: inherit;
  }

  & .jq-selectbox__select-text {
    @include adpt ($bmob-r) {
      line-height: 1.8;
    }
  }

  &__preview-text {
    font-weight: 700;
    font-size: 15px;
    margin-top: 5px;
  }

  &__preview-img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    margin-top: 10px;
  }

}