.footer {
  position: relative;
  margin-top: auto;


  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    max-width: 790px;
    max-height: 100%;

    @include adpt ($big-r) {
      left: -290px;
    }

    @include adpt ($tab-r) {
      left: -380px;
    }

    @include adpt ($mtab-r) {
      width: 890px;
      height: auto;
      left: -460px;
      max-width: initial;
    }

    @include adpt ($stab-r) {
      left: 0;
      top: 50px;
      width: 183px;
    }
  }

  &__inner-wrap {
    padding: 100px 0;
    overflow: hidden;
    position: relative;
    z-index: 2;

    @include adpt ($mtab-r) {
      padding: 70px 0;
    }

    @include adpt ($stab-r) {
      padding: 60px 0 90px;
    }

  }


  &__inner {
    position: relative;
    z-index: initial;
    padding: 50px 65px 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include adpt ($big-r) {
      padding-right: 50px;
    }

    @include adpt ($tab-r) {
      padding-right: 16px;
    }

    @include adpt ($mtab-r) {
      padding: 30px 16px 30px 0;
    }

    @include adpt ($stab-r) {
      flex-direction: column;
      padding: 30px 18px;
    }

    @include adpt ($bmob-r) {
      padding: 30px 15px;
    }


    &::after {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      border: 1px solid $grey;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__left {
    position: relative;
    z-index: 3;
    max-width: 252px;
    width: 100%;
    margin-right: 90px;

    @include adpt ($stab-r) {
      margin-right: 0;
      margin-bottom: 60px;
      max-width: 100%;
    }
  }

  &__logo {
    margin-bottom: 40px;

    @include adpt ($stab-r) {
      max-width: 250px;
      margin-bottom: 20px;
    }
  }

  &__text-block {
    font-size: 15px;

    & p {
      &:not(:last-child) {
        margin-bottom: 10px;

        @include adpt ($stab-r) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__policy {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    font-size: 15px;

    @include adpt ($stab-r) {
      margin-top: 20px;
    }

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }

  &__grid {
    position: relative;
    z-index: 3;
    max-width: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(200px, 1fr) 1fr;
    grid-template-areas:
      "a b c"
      "a b d";
    grid-gap: 30px 70px;
    align-items: flex-start;

    @include adpt ($big-r) {
      grid-gap: 30px 60px;
    }

    @include adpt ($tab-r) {
      grid-gap: 30px;
    }

    @include adpt ($mtab-r) {
      grid-template-areas:
        "a b"
        "c d";
      grid-gap: 30px 40px;
      grid-template-columns: 1fr 1fr;
    }

    @include adpt ($bmob-r) {
      grid-gap: 30px 20px;
      grid-template-columns: 1fr minmax(167px, 1fr);
    }

    @include adpt ($smob-r) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "a a"
        "b b"
        "c d";
    }
  }

  &__list-wrap {
    &._a {
      grid-area: a;
    }

    &._b {
      grid-area: b;
    }

    &._c {
      grid-area: c;
    }

    &._d {
      grid-area: d;
    }
  }

  &__title {
    margin-bottom: 7px;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    &._two-column {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px 20px;
      max-width: 170px;

      @include adpt ($bmob-r) {
        grid-gap: 10px;
      }
    }
  }

  &__link {
    position: relative;

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;
    }
  }

  &__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
  }

  &__links-link {
    position: relative;

    &:hover {
      font-weight: 700;
      letter-spacing: -0.025em;

      &::before {
        // opacity: 1;
      }
    }

    &::before {
      position: absolute;
      content: attr(data-text);
      letter-spacing: -0.4px;
      top: 0;
      left: 0;
      width: 100%;
      color: $grey;
      height: 100%;
      padding: 0;
      font-weight: 700;
      transition: opacity 0.2s linear;
      opacity: 0;
    }
  }
}