.login {
  padding: 60px 0;
  margin-top: -60px;
  overflow: hidden;

  @include adpt ($bmob-r) {}

  &__wrap {
    position: relative;
  }

  &__img-decor-wrap {
    aspect-ratio: 683/617;
    width: 100%;
    max-width: 670px;
    height: auto;
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -30%;
    background-image: url('../../../images/decor/login-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    max-height: 100%;

    @include adpt ($tab-r) {
      bottom: 70px;
    }

    @include adpt ($stab-r) {
      display: none;
    }
  }

  &__img-decor {
    position: absolute;
    left: 58px;
    bottom: 65px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

    @include adpt ($tab-r) {
      bottom: -80px;
    }
  }

  &__title {
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
  }

  &__social {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;

    @include adpt ($bmob-r) {
      margin-bottom: 50px;
    }
  }

  &__social-list {
    display: flex;
    width: 100%;

    @include adpt ($tab-r) {
      flex-direction: column;
    }
  }

  &__social-list-1 {
    margin-right: 30px;
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 611px;

    @include adpt ($tab-r) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    @include adpt ($bmob-r) {
      margin-bottom: 6px;
    }
  }

  &__social-item-1 {
    flex: 1;

    &:not(:last-child) {
      margin-right: 30px;

      @include adpt ($bmob-r) {
        margin-right: 6px;
      }
    }
  }


  &__social-link-1 {
    display: block;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid $grey;
    position: relative;
    transition: background-color 0.3s;
    background-color: $white;

    @include adpt (400) {
      padding: 10px;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    & span {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: $grey;
      font-size: 18px;

      @include adpt ($bmob-r) {
        font-size: 15px;
      }

      @include adpt (400) {
        bottom: 5px;
        right: 10px;
      }
    }
  }

  &__social-list-2 {
    display: flex;
    flex: 1;
  }

  &__social-item-2 {
    flex: 1;

    @include adpt ($tab-r) {
      flex: initial;
    }

    @include adpt ($bmob-r) {
      flex: 1;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include adpt ($bmob-r) {
        margin-right: 6px;
      }
    }
  }

  &__social-link-2 {
    width: 100px;
    height: 100px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid $grey;
    transition: background-color 0.3s;
    background-color: $white;

    @include adpt ($bmob-r) {
      aspect-ratio: 1/1;
      padding: 5px;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__subtext {
    margin-bottom: 20px;

    @include adpt ($bmob-r) {
      margin-bottom: 30px;
    }
  }

  &__inner {
    max-width: 611px;
    position: relative;
    z-index: 1;
  }

  &__text {
    & a {
      text-decoration: underline;
    }
  }

  &__link {
    padding: 20px;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-bottom: 20px;

    @include adpt ($bmob-r) {
      grid-gap: 5px;
      margin-bottom: 30px;
    }
  }
}