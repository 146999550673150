.authorization {
  overflow-x: hidden;
  flex: 1;
  padding: 60px 0 170px;
  margin-top: -60px;

  @include adpt ($bmob-r) {
    padding: 25px 0 50px;
    margin-top: 0;
  }

  &__wrap {
    position: relative;
  }

  &__decor {
    position: absolute;
    z-index: 0;
    background-image: url('../../../images/decor/authorization__decor.svg');
    aspect-ratio: 659/609;
    max-width: 635px;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
    right: -104px;
    top: -60px;
    pointer-events: none;

    @include adpt ($stab-r) {
      display: none;
    }
  }

  &__decor-img {
    position: absolute;
    left: 97px;
    bottom: 120px;

    @include adpt ($tab-r) {
      left: 120px;
      bottom: 100px;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    margin-bottom: 50px;

    @include adpt ($tab-r) {
      margin-bottom: 30px;
    }

    @include adpt ($bmob-r) {
      text-align: center;
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
    max-width: 590px;

    @include adpt ($bmob-r) {
      text-align: center;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__btn-wrap {
    display: flex;
    margin-bottom: 30px;
    align-items: center;

    @include adpt ($bmob-r) {
      flex-direction: column;
      max-width: 210px;
      margin: 0 auto 50px;
    }
  }

  &__btn {
    flex: 1;
    padding: 10px 30px;

    @include adpt ($bmob-r) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 20px;

      @include adpt ($bmob-r) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
}