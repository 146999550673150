.aboutUser {
  &__main {
    border-radius: 20px;
    padding: 40px 30px;
    background-color: $blueLight;
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 50px;
  }

  &__subtitle {
    margin-bottom: 20px;

    &._mb-30 {
      margin-bottom: 30px;
    }
  }

  &__sub-block-title {
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }

  &__item-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__label {
    display: block;
    margin-right: 10px;
    white-space: nowrap;
  }

  &__right {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__input {
    font-weight: 700;
    margin-right: 10px;
    max-width: 100%;
    width: 100%;
    transition: all 0.3s;

    &._input {
      padding: 8px 20px;
      max-width: 400px;
      border: 1px solid $grey;
      border-radius: 20px;
      outline: none;
    }

    &._error {
      border-color: red;
      box-shadow: 0 0 10px rgba(228, 64, 23, 0.3);
      color: red;
    }
  }

  &__doc-block-top {
    height: 200px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      & .aboutUser__doc-block-img-wrap {
        background-color: rgba(255, 255, 255, 0);
      }

      & .aboutUser__doc-block-top-text-inner {
        opacity: 0;
        pointer-events: none;

        &._2 {
          opacity: 1;
          pointer-events: all;
        }
      }

      & .aboutUser__doc-block-img-pdf {
        opacity: 1;
        pointer-events: all;

      }
    }

    &._red {
      border-color: #FF8080;
    }

    & .ant-image-img {
      height: 200px;
      object-fit: cover;
    }
  }

  &__doc-block-img-pdf {
    position: relative;
    z-index: 20;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tablo {
    margin-bottom: 50px;
    margin-top: 48px;

    @include adpt ($bmob-r) {
      margin-top: 30px;
    }
  }

  &__tablo-inner {
    position: relative;
    background-color: $yellowLight;
    padding: 40px 30px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(#5b8089, 0.3);
    max-width: 652px;

    @include adpt ($bmob-r) {
      padding: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      right: -100px;
      bottom: 0;
      width: 70px;
      height: 200px;
      object-fit: contain;
      background-image: url('../../../images/icon/image-znak.svg');

      @include adpt ($stab-r) {
        display: none;
      }
    }
  }

  &__tablo-text {
    @include adpt ($bmob-r) {
      font-size: 15px;
    }
  }

  &__tablo-subtitle {
    color: #FF8080;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;

    @include adpt ($bmob-r) {
      font-size: 18px;
    }
  }

  &__doc-block-top-text-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 9px;
    font-size: 18px;
    width: 101%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.3s;

    @include adpt ($bmob-r) {
      font-size: 15px;
    }

    &._img {
      background-color: rgba(255, 255, 255, 0.84);
    }


    &._pdf {
      background-color: $blueLight;
    }

  }

  &__doc-block-img-wrap {
    background-color: rgba(255, 255, 255, 0.84);
    transition: background-color 0.3s;
  }

  &__doc-block-img-wrap {
    height: 100%;
  }

  &__doc-block-item {
    &._hidden {
      display: none;
    }
  }

  &__doc-block-img-pdf {
    height: 100%;
    width: 100%;
    background-color: $blueLight;
  }

  &__btn-wrap {
    display: flex;
    flex-shrink: 0;
    align-self: flex-start;
    align-items: center;
  }

  &__btn {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  &__block-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__sub-block {
    &:first-child {
      padding-right: 29px;
      border-right: 2px solid $grey;
    }

    &:last-child {
      padding-left: 30px;
    }
  }

  &__doc-block {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  &__doc-block-title {
    padding-bottom: 10px;
    border-bottom: 1px solid $grey;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 30px;
  }

  &__doc-block-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    @include adpt ($stab-r) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include adpt ($bmob-r) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px 20px;
    }
  }

  &__doc-block-bottom {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;
  }

  & .ant-upload-select {
    display: block;
    width: 100%;
  }

  &__doc-block-btn {
    &._danger {
      border-color: #FF8080;
      color: #FF8080;
    }

    &._suc {
      color: $white;
      background-color: $blue;
      border-color: $blue;
    }

    &._green {
      background-color: $green;
      color: $white;
      border-color: $green;
    }

    &._red {
      background-color: #FF8080;
      border-color: #FF8080;
      color: $white;

    }

    &._download {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & .ant-upload-list {
    display: none;
  }
}