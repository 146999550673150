.editor {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid $grey;
  background-color: $white;

  & ul {
    list-style-position: inside;

    & li {
      position: relative;
      display: flex;

      &::before {
        content: "-";
        margin-right: 5px;
      }
    }
  }

  & ol {
    list-style-position: inside;
  }

  & ol li {
    &::marker {}
  }

  & .ck-list-bogus-paragraph {
    display: initial;
  }

  & figure {
    &.table {
      display: table;
      margin: 0.9em auto;

      & table {
        border: 1px double #b3b3b3;
        border-collapse: collapse;
        border-spacing: 0;
        height: 100%;
        width: 100%;

        & td {
          border: 1px solid #bfbfbf;
          min-width: 2em;
          padding: 0.4em;
          overflow-wrap: break-word;
          position: relative;
        }
      }
    }
  }

  & blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  & .image {
    clear: both;
    display: table;
    margin: 0.9em auto;
    min-width: 50px;
    text-align: center;

    &>figcaption {
      background-color: #f7f7f7;
      caption-side: bottom;
      color: #333;
      display: table-caption;
      font-size: .75em;
      outline-offset: -1px;
      padding: 0.6em;
      word-break: break-word;
    }

    & img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
  }

  & .image-style-side {
    float: right;
    margin-left: 1.5em;
    max-width: 50%;
  }

  & .image-inline,
  & .image_resized {
    align-items: flex-start;
    display: inline-flex;
    max-width: 100%;

    & img {
      height: auto;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
  }

  & .image_resized {
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
  }

  & a {
    text-decoration: underline;
    background: none !important;

    &:hover {
      color: $blue;
    }
  }

  & ._art-1 {
    font-size: 25px;
    font-weight: 700;
    display: block;
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $green;
    }
  }

  & ._art-2 {
    font-size: 25px;
    font-weight: 700;
    display: block;
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $purple;
    }
  }

  & ._art-3 {
    font-size: 25px;
    font-weight: 700;
    display: block;
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $blue;
    }
  }
}

.katex-html {
  display: none;
}

.ck-powered-by {
  display: none !important;
}

.ck-editor__editable .ck-list-bogus-paragraph {
  display: initial;
}

.ck-math-tex-display {
  text-align: center;
}